<script>
import Layout from "../../../layouts/main.vue";
// import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import axios from 'axios';
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DropZone from "@/components/widgets/dropZone";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/flatpickr.css";
//import { useSound } from '@vueuse/sound'
//import buttonSfx from '../assets/sounds/sound.mp3'
//import { ref, watch } from "vue";

import {
  required,
  helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";



export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      chatData: [],
      dateTimeConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i:s",
      },
      rootulr: appConfig.api_url,
      chatMessagesData: [],
      submitted: false,
      form: {
        message: "",
      },
      login_user_work_type: localStorage.getItem('work_type_string'),
            login_user_work_type_array: JSON.parse(localStorage.getItem('work_type_array')),
      profile: require("@/assets/images/users/avatar-2.jpg"),
      totalprofiles: '0',
      totalusers: '0',
      totalprojects: '0',
      usertype: localStorage.getItem('usertype'),
      userslist: [],
      progress_status: [],
      totalamount: 0,
      totalwords: 0,
      writers: [],
      words: 0,
      file: [],
      files: [],
      writer_expiry: '',
      plagiarism: 0,
      grammerly: 'No',
      notes: '',
      description: '',
      attachment_name: '',
      progress_status_modal: '',
      project_id: -1,
      assigned_to: -1,
      editor: ClassicEditor,
      modaltitle: '',
      buttonname: '',
      showwriter: false,
      regError: '',
      registerSuccess: false,
      isRegisterError: false,
      username: 'All',
      progress_status_filter:'-1',
      from_date:'',
      to_date:'',
      work_type:'-1',
      order_by:'office_completion_date',
      sort_by:'DESC',
      currentselectedchatname: 'All',
      currentselecteddatetime: '',
      config: {
        dateFormat: 'Y-m-d H:i',
        enableTime: true,
          time_24hr: true
      },
      is_filtered:false,

      content: "<h1>Some initial content</h1>",
      plugins: [
        "advlist autolink link lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 200,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
    };
  }, mounted: function () {
    this.GetAllDocuments(true);

    var currentChatId = "users-chat";
    this.scrollToBottom(currentChatId);
    //document.getElementById('copyClipBoard').style.display = 'none';
    var userChatElement = document.querySelectorAll(".user-chat");
    document.querySelectorAll(".chat-user-list li a").forEach(function (item) {
      item.addEventListener("click", function () {
        userChatElement.forEach(function (elm) {
          elm.classList.add("user-chat-show");
        });

        // chat user list link active
        var chatUserList = document.querySelector(".chat-user-list li.active");
        if (chatUserList) chatUserList.classList.remove("active");
        this.parentNode.classList.add("active");
      });
    });

    // user-chat-remove
    document.querySelectorAll(".user-chat-remove").forEach(function (item) {
      item.addEventListener("click", function () {
        userChatElement.forEach(function (elm) {
          elm.classList.remove("user-chat-show");
        });
      });
    });

    window.setInterval(() => {
      if(this.is_filtered == false) this.GetAllDocuments(false);
     }, 10000)

  }, computed: {

  }, validations: {
    form: {
      message: {
        required: helpers.withMessage("Message is required", required),
      },
    },
  },
  methods: {
    deleteRecord(index) {
      this.files.splice(index, 1)
    }, async GetAllDocuments(called_first_time) {
      this.is_filtered = false;
      this.from_date='';
      this.to_date='';
      this.work_type='-1';
      this.order_by='office_completion_date';
      this.sort_by='DESC';
      this.progress_status_filter ='-1'; 
      //var userid = localStorage.getItem('userid')
      //  alert("userid==" + localStorage.getItem('usertype'));
      if(localStorage.getItem('usertype') == 'Super Admin' || localStorage.getItem('usertype') == 'Bidder') this.order_by='due_date'
      var result = await axios.post(appConfig.api_url + 'getstats', {
        filter: '',
        usertype: localStorage.getItem('usertype'),
        userid: localStorage.getItem('userid'),
        from_date: '',
        to_date:'',
        work_type:'-1',
        work_type_String: this.login_user_work_type,
        order_by: this.order_by,
        sort_by: 'ASC',
        progress_status: '-1',
      })

      // alert(JSON.stringify(result.data));

      //alert(docs);
      //console.log(JSON.stringify(docs));
      this.totalprofiles = result.data.totalprofiles;
      this.totalusers = result.data.totalusers;
      var newtotalprojects = parseInt(result.data.totalprojects);
      var previostotalprojects = parseInt(this.totalprojects);
      // alert(this.totalprojects+ " = new project comes ==" +newtotalprojects);
     
      //useSound('@/assets/sound/sound.mp3');
      if (newtotalprojects > previostotalprojects && called_first_time == false) { 
         var audio = new Audio(require('/public/sound.mp3'));
         audio.play();
        toast("New project arrived", {
              "theme": "dark",
              "type": "info",
              "autoClose": false,
              "transition": "flip",
              "dangerouslyHTMLString": true
        })
        
          
      }

      this.totalprojects = result.data.totalprojects;
      this.progress_status = result.data.progress_status;
      this.totalwords = result.data.totalwords;
      this.totalamount = result.data.totalamount;
      if (result.data.status == 'errors') {
        return this.authError = result.data.data.data;
      }

      result = await axios.post(appConfig.api_url + 'project/getallforuser', {
        filter: '',
        usertype: localStorage.getItem('usertype'),
        userid: localStorage.getItem('userid'),
        from_date: '',
        to_date:'',
        work_type:this.work_type,
        work_type_String: this.login_user_work_type,
        order_by: 'due_date',
        sort_by: 'DESC',
        progress_status:'-1',
      })

      var users = result.data.data.final_array;
      // alert(users.length);
      console.log(users);
      this.userslist = users;
      this.writers = result.data.data.writer;

    }, async filter_data()
    {
      this.is_filtered = true;
      //var userid = localStorage.getItem('userid')
      //alert(this.work_type+"======" + this.login_user_work_type);

      var result = await axios.post(appConfig.api_url + 'getstats', {
        filter: '',
        usertype: localStorage.getItem('usertype'),
        userid: localStorage.getItem('userid'),
        from_date: this.from_date+":00",
        to_date:this.to_date +":59",
        work_type:this.work_type,
        work_type_String: this.login_user_work_type,
        order_by: this.order_by,
        sort_by: this.sort_by,
        progress_status: this.progress_status_filter,
      })

      // alert(JSON.stringify(result.data));

      //alert(docs);
      //console.log(JSON.stringify(docs));
      this.totalprofiles = result.data.totalprofiles;
      this.totalusers = result.data.totalusers;
      this.totalprojects = result.data.totalprojects;
      this.progress_status = result.data.progress_status;
      this.totalwords = result.data.totalwords;
      this.totalamount = result.data.totalamount;
     
      result = await axios.post(appConfig.api_url + 'project/getallforuser', {
        filter: '',
        usertype: localStorage.getItem('usertype'),
        userid: localStorage.getItem('userid'),
        from_date: this.from_date,
        to_date:this.to_date,
        work_type:this.work_type,
        work_type_String: this.login_user_work_type,
        order_by: this.order_by,
        sort_by: this.sort_by,
        progress_status: this.progress_status_filter,
      })

      var users = result.data.data.final_array;
      // alert(JSON.stringify(users));
      console.log(users);
      this.userslist = users;
      this.writers = result.data.data.writer;

    }, onselect() {
      this.file = this.$refs.file.files[0];

    }, updatewriter() {
      //alert(this.showwriter);
      if (this.progress_status_modal == 'Assigned' || this.progress_status_modal == 'Assigned Partial' || this.progress_status_modal == 'Rework') {
        this.showwriter = true
      } else {
        this.showwriter = false
      }
      // alert(this.showwriter);
    }, updateuser(project_id) {

      this.modaltitle = 'Create New Session Thread';
      this.buttonname = 'Create Session';


      this.project_id = project_id;
      this.showwriter = false;
      this.notes = "";
      this.description = '';
      this.progress_status_modal = "";
      this.file = null;
      this.words = 0;
      this.grammerly = 'No',
        this.plagiarism = 0;
      this.attachment_name = '';
      this.assigned_to = -1;

      document.querySelector(".dropzoneFile").value = '';
      this.files = [];
      this.isRegisterError = false;
      this.registerSuccess = false;
      this.submitted = false;
      document.getElementById('openupdatemodalbutton').click();


    }, async updateuserprofile() {
      this.submitted = true;
      // stop here if form is 
      //  alert('Select Writer Expiry Date=' + this.writer_expiry);
      if (this.progress_status_modal === 'Assigned' || this.progress_status_modal === 'Edits' || this.progress_status_modal === 'Rework') {
        // alert("=="+this.writer_expiry+"==")
        if (this.writer_expiry === '') {
          this.regError = 'Select writer expiry date and time';
          // this.registerSuccess = false;
          this.isRegisterError = true;
          this.submitted = false;
          return;
        }

      }

      // alert(appConfig.api_url + '' + goto);
      let date_ob = new Date();
      let date = ("0" + date_ob.getDate()).slice(-2);
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
      let year = date_ob.getFullYear();
      let hours = date_ob.getHours();
      let minutes = date_ob.getMinutes();
      let seconds = date_ob.getSeconds();
      let millisecond = date_ob.getMilliseconds();

      var idatetime = year + "" + month + "" + date + "" + hours + "" + minutes + "" + seconds + "" + millisecond;

      let formData = new FormData();

      if (this.progress_status_modal !== 'Assigned' && this.progress_status_modal !== 'Assigned Partial' && this.progress_status_modal !== 'Rework') {
        this.assigned_to = -1;
      }


      formData.append("progress_status", this.progress_status_modal);
      formData.append("assigned_to", this.assigned_to);
      formData.append("plagiarism", this.plagiarism);
      formData.append("grammerly", this.grammerly);
      formData.append("project_id", this.project_id);
      formData.append("notes", this.notes);
      formData.append("words", this.words);
      formData.append("attachment_name", idatetime);
      formData.append("description", this.description);
      formData.append("createdby", localStorage.getItem('userid'));

      for (var i = 0; i < document.querySelector(".dropzoneFile").files.length; i++) {
        let filei = document.querySelector(".dropzoneFile").files[i];
        //formData.append('files[' + i + ']', file);
        // dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
        //  this.file.push(filei);
        // this.file.push(filei);
        formData.append("file", filei);
        // files.value.push(dropzoneFile.value);
        console.log("====total files===" + i);
      }
      // console.log("====total files==="+this.file.length);

      // formData.append("file", this.file);
      formData.append("writer_expiry", this.writer_expiry);

      // for (var pair of formData.entries()) {
      //   console.log("====" + pair[0] + ', ' + pair[1]);
      // }

      //  console.log(this.file)

      const result = await axios.post(appConfig.api_url + 'project/createsession', formData);

      //alert(JSON.stringify(result.data))

      if (result.data.status == 'errors') {

        this.regError = result.data.data.data;
        this.registerSuccess = false;
        this.isRegisterError = true;
        this.submitted = true;
        // return this.authError = result.data.data.data;
      } else {

        this.isRegisterError = false;
        this.registerSuccess = true;
        this.regSuccess = result.data.data.data;
        this.GetAllDocuments();
        this.submitted = false;
        //this.getUserData();
        document.getElementById('closeupdatemodalbutton').click();

      }


    }, async opensession(project_id) {

      var result = await axios.post(appConfig.api_url + 'project/getsession', {
        project_id: project_id
      })

      this.chatData = result.data.data.chatsession;
      this.chatMessagesData = this.chatData
      //alert(JSON.stringify(this.chatData));
      //console.log(users);


      this.project_id = project_id;

      document.getElementById('opensessionbutton').click();

    }, async delete_single_session(session_id) {

      // alert(session_id);
      var result = await axios.post(appConfig.api_url + 'project/delete_single_session', {
        session_id: session_id
      })

      //alert(JSON.stringify(result.data))

      result = await axios.post(appConfig.api_url + 'project/getsession', {
        project_id: this.project_id
      })

      this.chatData = result.data.data.chatsession;
      this.chatMessagesData = this.chatData

    },
    scrollToBottom(id) {
      setTimeout(function () {
        var simpleBar = document.getElementById(id).querySelector(
          "#chat-conversation .simplebar-content-wrapper") ?
          document.getElementById(id).querySelector(
            "#chat-conversation .simplebar-content-wrapper") : ''

        var offsetHeight = document.getElementsByClassName("chat-conversation-list")[0] ?
          document.getElementById(id).getElementsByClassName("chat-conversation-list")[0]
            .scrollHeight - window.innerHeight + 600 : 0;

        if (offsetHeight)
          simpleBar.scrollTo({
            top: offsetHeight,
            behavior: "smooth"
          });
      }, 300);
    },
    chatUsername(index) {

      this.username = index;
      // alert(index);
      if (index === -1) {

        this.chatMessagesData = this.chatData;
        this.currentselectedchatname = 'All';
        this.currentselecteddatetime = '';
      } else {
        var a = [this.chatData[index]];
        this.chatMessagesData = a;
        // alert(JSON.stringify(this.chatMessagesData))
        this.currentselectedchatname = this.chatData[index].progress_status;
        this.currentselecteddatetime = this.chatData[index].customecreatedat;
      }
    },
    formSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        const message = this.form.message;
        const currentDate = new Date();
        this.chatMessagesData.push({
          align: "right",
          name: "Henry Wells",
          message,
          time: currentDate.getHours() + ":" + currentDate.getMinutes(),
        });
        var currentChatId = "users-chat";
        this.scrollToBottom(currentChatId);
      }
      this.submitted = false;
      this.form = {};
    }, selectedFile() {
      //alert("called selectedfiles");
      for (var i = 0; i < document.querySelector(".dropzoneFile").files.length; i++) {
        let filei = document.querySelector(".dropzoneFile").files[i];
        //formData.append('files[' + i + ']', file);
        // dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
        //  this.file.push(filei);
        this.files.push(filei);
        // files.value.push(dropzoneFile.value);
      }
    }, dropFile(e) {
      //alert("called drop called");
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        let filei = e.dataTransfer.files[i];
        //formData.append('files[' + i + ']', file);
        // dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
        //  this.file.push(filei);
        this.files.push(filei);
        // files.value.push(dropzoneFile.value);
      }
    }

  },
  
  components: {
    Layout,
    // PageHeader,
    ckeditor: CKEditor.component,
    flatPickr,
    DropZone


  },
};
</script>

<template>
  <Layout>

    <!-- <PageHeader :title="title" :items="items" /> -->


    <div class="row pb-2 pt-2 pt-2" style="background-color:lightblue" v-if="usertype == 'Super Admin' || usertype == 'Admin'">

      <div class="col-lg-2 col-sm-6" v-for="(ps, index) of progress_status" v-bind:key="index">
        <span v-if="ps.progress_status == 'New Work'">
          <div class="p-2 border border-dashed rounded text-center" style="background-color:#d6ffbc;color:black">
            <div>
              <p class=" fw-medium mb-1" style="background-color:#d6ffbc;color:black;font-size:17px">
                {{ ps.progress_status }}</p>
              <h5 class="fs-17  mb-0" style="background-color:#d6ffbc;color:black;font-size:14px"> {{ ps.count }}</h5>
            </div>
          </div>
        </span>
        <span v-if="ps.progress_status == 'Assigned'">
          <div class="p-2 border border-dashed rounded text-center" style="background-color:#5d86ef;color:white">
            <div>
              <p class=" fw-medium mb-1" style="background-color:#5d86ef;color:white;font-size:17px">
                {{ ps.progress_status }}</p>
              <h5 class="fs-17  mb-0" style="background-color:#5d86ef;color:white;font-size:14px"> {{ ps.count }}</h5>
            </div>
          </div>
        </span>
        <span v-if="ps.progress_status == 'Assigned Partial'">
          <div class="p-2 border border-dashed rounded text-center" style="background-color:rgb(25 51 116);color:white">
            <div>
              <p class=" fw-medium mb-1" style="background-color:rgb(25 51 116);color:white;font-size:17px">
                {{ ps.progress_status }}</p>
              <h5 class="fs-17  mb-0" style="background-color:rgb(25 51 116);color:white;font-size:14px"> {{ ps.count }}
              </h5>
            </div>
          </div>
        </span>
        <span v-if="ps.progress_status == 'Writer Completed'">
          <div class="p-2 border border-dashed rounded text-center" style="background-color:#bc9f05;color:white">
            <div>
              <p class=" fw-medium mb-1" style="background-color:#bc9f05;color:white;font-size:17px">
                {{ ps.progress_status }}</p>
              <h5 class="fs-17  mb-0" style="background-color:#bc9f05;color:white;font-size:14px"> {{ ps.count }}</h5>
            </div>
          </div>
        </span>
        <span v-if="ps.progress_status == 'Rework'">
          <div class="p-2 border border-dashed rounded text-center" style="background-color:#88027b;color:white">
            <div>
              <p class=" fw-medium mb-1" style="background-color:#88027b;color:white;font-size:17px">
                {{ ps.progress_status }}</p>
              <h5 class="fs-17  mb-0" style="background-color:#88027b;color:white;font-size:14px"> {{ ps.count }}</h5>
            </div>
          </div>
        </span>
        <span v-if="ps.progress_status == 'Ready for Client'">
          <div class="p-2 border border-dashed rounded text-center" style="background-color:#124b18;color:white">
            <div>
              <p class=" fw-medium mb-1" style="background-color:#124b18;color:white;font-size:17px">
                {{ ps.progress_status }}</p>
              <h5 class="fs-17  mb-0" style="background-color:#124b18;color:white;font-size:14px"> {{ ps.count }}</h5>
            </div>
          </div>
        </span>
        <span v-if="ps.progress_status == 'Edits'">
          <div class="p-2 border border-dashed rounded text-center" style="background-color:#ffd3d3;color:black">
            <div>
              <p class=" fw-medium mb-1" style="background-color:#ffd3d3;color:black;font-size:17px">
                {{ ps.progress_status }}</p>
              <h5 class="fs-17  mb-0" style="background-color:#ffd3d3;color:black;font-size:14px"> {{ ps.count }}</h5>
            </div>
          </div>
        </span>
        <span v-if="ps.progress_status == 'Submitted to Client'">
          <div class="p-2 border border-dashed rounded text-center" style="background-color:rgb(70 75 24);color:white">
            <div>
              <p class=" fw-medium mb-1" style="background-color:rgb(70 75 24);color:white;font-size:17px">
                {{ ps.progress_status }}</p>
              <h5 class="fs-17  mb-0" style="background-color:rgb(70 75 24);color:white;font-size:14px"> {{ ps.count }}
              </h5>
            </div>
          </div>
        </span>
        <span v-if="ps.progress_status == 'Project Completed'">
          <div class="p-2 border border-dashed rounded text-center" style="background-color: rgb(161 83 8);color:white">
            <div>
              <p class=" fw-medium mb-1" style="background-color:     rgb(161 83 8);color:white;font-size:17px">
                <!-- {{ ps.progress_status }} -->
                  Pro. Completed
              </p>
              <h5 class="fs-17  mb-0" style="background-color:   rgb(161 83 8);color:white;font-size:14px"> {{
                ps.count
              }}
              </h5>
            </div>
          </div>
        </span>
        <span v-if="ps.progress_status == 'Refunded'">
          <div class="p-2 border border-dashed rounded text-center" style="background-color: black;color:white">
            <div>
              <p class=" fw-medium mb-1" style="background-color:black;color:white;font-size:17px">
                {{ ps.progress_status }}</p>
              <h5 class="fs-17  mb-0" style="background-color:black;color:white;font-size:14px"> {{ ps.count }}
              </h5>
            </div>
          </div>
        </span>
        <span v-if="ps.progress_status == 'Cancelled'">
          <div class="p-2 border border-dashed rounded text-center"
            style="background-color: rgb(234 234 234);color:black">
            <div>
              <p class=" fw-medium mb-1" style="background-color: rgb(234 234 234);color:black;font-size:17px">
                {{ ps.progress_status }}</p>
              <h5 class="fs-17  mb-0" style="background-color: rgb(234 234 234);color:black;font-size:14px">
                {{ ps.count }}
              </h5>
            </div>
          </div>
        </span>

      </div>
      <div class="col-lg-2 col-sm-6">
        <div class="p-2 border border-dashed rounded text-center" style="background-color:coral;color:white">
          <div>
            <p class=" fw-medium mb-1">T. Projects</p>
            <h5 class="fs-17  mb-0"> {{ totalprojects }}</h5>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-2 col-sm-6">
        <div class="p-2 border border-dashed rounded text-center">
          <div>
            <p class=" fw-medium mb-1">T. Words</p>
            <h5 class="fs-17  mb-0"> {{ totalwords }}</h5>
          </div>
        </div>
      </div> -->
      <div class="col-lg-2 col-sm-6">
        <div class="p-2 border border-dashed rounded text-center" style="background-color:#bc9f05;color:white">
          <div>
            <p class=" fw-medium mb-1">T. Amount</p>
            <h5 class="fs-17  mb-0"> USD {{ totalamount }}</h5>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-2 col-sm-6">
        <div class="p-2 border border-dashed rounded text-center">
          <div>
            <p class=" fw-medium mb-1">T. Users</p>
            <h5 class="fs-17  mb-0"> {{ totalusers }}</h5>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6">
        <div class="p-2 border border-dashed rounded text-center">
          <div>
            <p class=" fw-medium mb-1">T. Profiles</p>
            <h5 class="fs-17  mb-0"> {{ totalprofiles }}</h5>
          </div>
        </div>
      </div> -->
      <!-- end col -->

    </div>

    <div class="row mb-0 pt-2 pt-2" style="background-color: lightcyan">
            <!-- <h3 class="m-2">Profiles</h3> -->
            <!-- <div class="page-title-box d-sm-flex align-items-left justify-content-between"> -->
            <div class="page-title-box" v-if="usertype !== 'Writer'" style="padding-bottom: 0px;padding-top: 10 px">
                <div class="row m-0 ">
                 
                    <div class="col-lg-2">
                        <div>
                            <label for="firstName" class="form-label">From</label>
                            <flatPickr class="form-control" :config="config" v-model="from_date"></flatPickr>
                            <!-- <input type="date" v-model="from_date" formate="yyyy-mm-dd" class="form-control mb-3" /> -->
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div>
                            <label for="firstName" class="form-label">To</label>
                             <flatPickr class="form-control" :config="config" v-model="to_date"></flatPickr>
                            <!-- <input type="date" v-model="to_date" class="form-control mb-3" /> -->
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div>
                            <label for="firstName" class="form-label">Progress Status</label>
                            <select v-model="progress_status_filter" class="form-select mb-2" multiple aria-label="multiple select example">
                                <option value="-1">All</option>
                                <option value="New Work">New Work</option>
                                <option value="Assigned">Assigned</option>
                                <!-- <option value="Assigned Partial">Assigned Partial</option> -->
                                <option value="Writer Completed">Writer Completed</option>
                                <option value="Rework">Rework</option>
                                <option value="Ready for Client" v-if="usertype == 'Super Admin' || usertype == 'Bidder'">Ready for Client</option>
                                <option value="Edits">Edits</option>
                                <option value="Submitted to Client" v-if="usertype == 'Super Admin' || usertype == 'Bidder'">Submitted to Client</option>
                               
                                <option value="Refunded" v-if="usertype == 'Super Admin' || usertype == 'Bidder'">Refunded</option>
                                <option value="Cancelled" v-if="usertype == 'Super Admin' || usertype == 'Bidder'">Cancelled</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <label for="firstName" class="form-label">Work Type</label>
                        <select v-model="work_type" class="form-select mb-3" aria-label="Default select example">
                            <option value="-1">All</option>
                            <option v-for="item of login_user_work_type_array" v-bind:key="item.id" :value="item.id" >{{ item.name }}</option>
                            <!-- <option value="Writing">Writing</option>
                            <option value="Premium Writing">Premium Writing</option>
                            <option value="Professional Writing">Professional Writing</option>
                            <option value="Technical">Technical</option>
                            <option value="Others">Others</option> -->
                        </select>
                    </div>
                    <div class="col-lg-2" >
                        <div>
                            <label for="firstName" class="form-label">Order By</label>
                            <select v-model="order_by" class="form-select mb-3" aria-label="Default select example">
                                <option value="createdat" v-if="usertype == 'Super Admin' || usertype == 'Bidder' || usertype == 'Admin'">Project Creation Date</option>
                                <option value="due_date" v-if="usertype == 'Super Admin' || usertype == 'Bidder' || usertype == 'Admin'" >Real Due Date</option>
                                <option value="office_completion_date">
                                    Task Manager Completion Date
                                </option>
                                <!-- <option value="work_type">Work Type</option> -->
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-1" v-if="usertype !== 'Writer'">
                        <div>
                            <label for="firstName" class="form-label">Sort By</label>
                            <select v-model="sort_by" class="form-select mb-3" aria-label="Default select example">
                                <option value="DESC">Desending</option>
                                <option value="ASC">Ascending</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <button type="submit" style="margin-top: 25px" class="btn btn-primary" @click="filter_data()">
                            FLTR
                        </button>
                        <button type="button" style="margin-top: 25px; margin-left: 10px" class="btn btn-primary"
                            @click="GetAllDocuments()">
                            CLR
                        </button>
                    </div>
                </div>
            </div>
        </div>

    <div class="card ">
      <div class="card-body m-0 p-0">
        <div class="col-12" style="padding-bottom:10px">
          <b-alert v-model="registerSuccess" style="padding-bottom:10px" variant="success" dismissible>
            {{ regSuccess }}
          </b-alert>
        </div>

        <div class="table-responsive table-card">
          <table class="table align-middle table-nowrap mb-0">
            <thead class="table-light">
              <tr>
                <th scope="col">Action</th>
                <th scope="col">ID <br> P. Code <span scope="col" v-if="usertype == 'Super Admin' || usertype === 'Bidder' || usertype === 'Admin'" ><br>Client name</span></th>
                <th scope="col">Progress Status</th>
                <th scope="col">Assigned To</th>
                <!-- <th scope="col">Attachment</th> -->
                <th v-if="usertype == 'Super Admin' || usertype === 'Bidder' || usertype === 'Admin'" scope="col">Creation Date <br> Real Due Date<br> Task Manager Date</th>
                <th v-if="usertype == 'Task Manager' " scope="col">Completion Date <br> Created At</th>
                <th scope="col">P. Title
                   <span scope="col" v-if="usertype == 'Super Admin' || usertype === 'Bidder' || usertype === 'Admin'" ><br>Work Type</span>
                   <span scope="col" v-if="usertype == 'Super Admin' || usertype === 'Bidder' || usertype === 'Admin'" ><br>At Profile</span>
                </th>
                <!-- <th scope="col">Client name / contact / email</th>
                <th scope="col">Amount</th> -->
                <th scope="col">Words <br> Ref. Style</th>
                
                
                <!-- <th v-if="usertype == 'Super Admin' || usertype === 'Bidder'" scope="col">Office Completion Date</th> -->

                <!-- <th scope="col">Status</th>
                <th scope="col">Create By / At</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user) of userslist" v-bind:key="user[1][0].id">
                <td>
                  <a @click="updateuser(user[1][0].id)"><span class="badge bg-primary"
                      style="font-size:14px;color:white;margin-right:5px">+ Create </span></a>
                  <a @click="opensession(user[1][0].id)"><span class="badge bg-danger"
                      style="font-size:14px;color:white;margin-right:5px">View</span> </a>
                </td>
                <td>{{ user[1][0].id }}<br>{{ user[1][0].code }}
                 <span v-if="usertype == 'Super Admin' || usertype === 'Admin' || usertype === 'Bidder'" ><br>{{ user[1][0].client_name }}</span>
                </td>

                <td v-if="user[1][0].progress_status == 'New Work'" style=" background-color:#d6ffbc;color:black">
                  <span>{{ user[1][0].progress_status }}</span>
                </td>
                <td v-else-if="user[1][0].progress_status == 'Assigned'" style="background-color:#5d86ef;color:white">
                  <span>{{ user[1][0].progress_status }}</span>
                </td>
                <td v-else-if="user[1][0].progress_status == 'Assigned Partial'"
                  style="background-color:rgb(25 51 116);color:white">
                  <span>{{ user[1][0].progress_status }}</span>
                </td>
                <td v-else-if="user[1][0].progress_status == 'Writer Completed'"
                  style="background-color:#bc9f05;color:white"><span>{{ user[1][0].progress_status }}</span></td>
                <td v-else-if="user[1][0].progress_status == 'Rework'" style="background-color:#88027b;color:white">
                  <span>{{ user[1][0].progress_status }}</span>
                </td>
                <td v-else-if="user[1][0].progress_status == 'Ready for Client'"
                  style="background-color:#124b18;color:white"><span>{{ user[1][0].progress_status }}</span></td>
                <td v-else-if="user[1][0].progress_status == 'Edits'" style="background-color:#ffd3d3;color:black">
                  <span>{{ user[1][0].progress_status }}</span>
                </td>
                <td v-else-if="user[1][0].progress_status == 'Submitted to Client'"
                  style="background-color:#369f43;color:white"><span>{{ user[1][0].progress_status }}</span></td>
                <td><span v-for="(each_assigned_writer, index) of user[0]" v-bind:key="index"
                    class="badge bg-secondary" style="font-size:14px;color:white;margin-right:5px">{{
                      each_assigned_writer.Name
                    }}</span></td>
                <!-- <td><span v-if="user[1][0].attachment_name !== ''"><a target="_blank"
                      :href="root_url + 'upload/projects/' + user[1][0].attachment_name">Download</a></span></td> -->
                  <td v-if="usertype === 'Super Admin' || usertype === 'Bidder' || usertype === 'Admin'">
                 
                    {{user[1][0].customecreatedat }}  <br>  <span style="color:red;">{{ user[1][0].custome_due_date }} </span> <br>{{user[1][0].customeoffice_completion_date}}
                    </td>
                  <td v-if="usertype === 'Task Manager'">
                  <span style="color:red;font-weight: bold;">{{
                    user[1][0].customeoffice_completion_date
                  }}</span> <br>
                {{
                    user[1][0].customecreatedat
                  }}  
                </td>
                <td>{{ user[1][0].project_title }}
               
                <span v-if="usertype == 'Super Admin' || usertype === 'Bidder' || usertype === 'Admin'" ><br>{{ user[1][0].Work_type_name }}</span>
                <span v-if="usertype == 'Super Admin' || usertype === 'Bidder' || usertype === 'Admin'" ><br>{{ user[1][0].profile_full_name }} ({{ user[1][0].profile_short_name }})</span>
                </td>
                <!-- <td>{{user[1][0].client_name}} / {{user[1][0].client_contact}} / {{user[1][0].client_email}}</td>
                <td>{{user[1][0].amount}}</td> -->
                <td>{{ user[1][0].words }} Words <br> {{ user[1][0].ref_style }}</td>
                
               
                <!-- <td v-if="usertype === 'Super Admin' || usertype === 'Bidder'">{{ user[1][0].customeoffice_completion_date }}</td> -->
                <!-- <td>{{user[1][0].status}}</td>
                <td>{{user[1][0].createdbyname}} / {{user[1][0].customecreatedat}}</td> -->

              </tr>

            </tbody>

          </table>
          <!-- end table -->
        </div>

        <div class="mt-4">
          <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">

              <p style="float:left">Rows: {{ totalrows }}</p>

              <div class="page-title-right" style="float:right">
                <paginate v-model="page" :page-count="totalpage" :margin-pages="2" :click-handler="clickCallback"
                  :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'" :page-class="'page-item'">
                </paginate>
              </div>
            </div>
          </div>
        </div>



        <!--end tab-pane-->


      </div>
    </div>
    

    <a data-bs-toggle="modal" id="openupdatemodalbutton" data-bs-target="#updatecreatedocumentsmodal"></a>
    <div class="modal fade" id="updatecreatedocumentsmodal" tabindex="-1" aria-labelledby="exampleModalgridLabel">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalheader">{{ modaltitle }}</h5>
            <button type="button" id="closeupdatemodalbutton" class="btn-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}
            </b-alert>

            <form @submit.prevent="updateuserprofile" id="updateDocumentform" enctype="multipart/form-data">
              <div class="row g-3">
                <!-- <div class="col-lg-2">
                  <div class="mb-1">
                    <label for="phonenumberInput" class="form-label">Important Notes</label>
                    <input type="text" class="form-control" id="mobile" v-model="notes" />
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-lg-2">
                    <div>
                      <label for="firstName" class="form-label">Select Status</label>
                      <select required @change="updatewriter()" v-if="usertype == 'Admin'"
                        v-model="progress_status_modal" class="form-select mb-3" aria-label="Default select example">
                        <option value="Assigned">Assigned</option>
                        <!-- <option value="Assigned Partial">Assigned Partial</option> -->
                        <option value="Writer Completed">Writer Completed</option>
                        <option value="Rework">Rework</option>
                        <option value="Ready for Client">Ready for Client</option>
                        <option value="Edits">Edits</option>
                        <option value="Submitted to Client">Submitted to Client</option>
                        <option value="Project Completed">Project Completed</option>
                        <option value="Refunded">Refunded</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                      <select required @change="updatewriter()" v-if="usertype == 'Super Admin'"
                        v-model="progress_status_modal" class="form-select mb-3" aria-label="Default select example">
                        <option value="Assigned">Assigned</option>
                        <!-- <option value="Assigned Partial">Assigned Partial</option> -->
                        <option value="Writer Completed">Writer Completed</option>
                        <option value="Rework">Rework</option>
                        <option value="Ready for Client">Ready for Client</option>
                        <option value="Edits">Edits</option>
                        <option value="Submitted to Client">Submitted to Client</option>
                        <option value="Project Completed">Project Completed</option>
                        <option value="Refunded">Refunded</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                      <select required @change="updatewriter()" v-if="usertype == 'Task Manager'"
                        v-model="progress_status_modal" class="form-select mb-3" aria-label="Default select example">
                        <option value="Assigned">Assigned</option>
                        <!-- <option value="Assigned Partial">Assigned Partial</option> -->
                        <!-- <option value="Writer Completed">Writer Completed</option> -->
                        <option value="Rework">Rework</option>
                        <option value="Ready for Client">Ready for Client</option>
                      </select>
                      <select required @change="updatewriter()" v-if="usertype == 'Bidder'"
                        v-model="progress_status_modal" class="form-select mb-3" aria-label="Default select example">
                        <option value="Edits">Edits</option>
                        <option value="Assigned">Assigned</option>
                        <option value="Submitted to Client">Submitted to Client</option>
                        <option value="Project Completed">Project Completed</option>
                        <option value="Refunded">Refunded</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                      <select required @change="updatewriter()" v-if="usertype == 'Writer'"
                        v-model="progress_status_modal" class="form-select mb-3" aria-label="Default select example">
                        <option value="Writer Completed">Writer Completed</option>
                      </select>
                      <input type="hidden" v-model="updateuser_id">

                    </div>
                  </div>

                  <div class="col-lg-2" v-if="(usertype == 'Task Manager' || usertype == 'Admin' || usertype == 'Super Admin') && showwriter == true">
                    <label for="firstName" class="form-label">Assigne To</label>
                    <select required v-model="assigned_to" class="form-select mb-3" aria-label="Default select example">
                      <option value="-1">None</option>
                      <option v-for="(writer) of writers" v-bind:key="writer.id" :value="writer.Id">{{ writer.Name }}
                      </option>
                    </select>
                  </div>
                  <!-- <div class="col-lg-2" v-if="usertype == 'Super Admin' && showwriter == true">
                    <label for="firstName" class="form-label">Assigne To</label>
                    <select required v-model="assigned_to" class="form-select mb-3" aria-label="Default select example">
                      <option v-for="(writer) of writers" v-bind:key="writer.id" :value="writer.Id">{{ writer.Name }}
                      </option>
                    </select>
                  </div> -->

                  <!--end col-->
                  <div class="col-lg-2"
                    v-if="progress_status_modal == 'Assigned' || progress_status_modal == 'Rework' || progress_status_modal == 'Edits'">
                    <div class="mb-1">
                      <label for="emailInput" class="form-label">Expiry for Writer</label>
                      <flat-pickr required v-model="writer_expiry" :config="dateTimeConfig"
                        class="form-control flatpickr-input">
                      </flat-pickr>
                    </div>
                  </div>
                  <div class="col-lg-2" v-if="progress_status_modal == 'Writer Completed'">
                    <div class="mb-1">
                      <label for="emailInput" class="form-label">Words</label>
                      <input type="number" class="form-control" v-model="words" />

                    </div>
                  </div>
                  <div class="col-lg-2" v-if="progress_status_modal == 'Writer Completed'">
                    <div class="mb-1">
                      <label for="emailInput" class="form-label">Plagiarism (%)</label>
                      <input type="number" class="form-control" v-model="plagiarism" />

                    </div>
                  </div>
                  <div class="col-lg-2" v-if="progress_status_modal == 'Writer Completed'">
                    <label for="firstName" class="form-label">Grammerly</label>
                    <select required v-model="grammerly" class="form-select mb-3" aria-label="Default select example">
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>
                </div>


                <div class="col-lg-6">
                  <ckeditor v-model="description" :editor="editor"></ckeditor>
                </div>
                <!--end col-->

                <!--end col-->
                <div class="col-lg-6">
                  <div class="row">


                    <div class="col-lg-12">

                      <!-- <input type="file" ref="file" id="file" name="file"> -->
                      <DropZone @drop.prevent="dropFile" @change="selectedFile" />
                      <ul class="list-unstyled mb-0" id="dropzone-preview">
                        <div class="border rounded" v-for="(file, index) of files" :key="index">
                          <div class="d-flex p-2">
                            <div class="flex-grow-1">
                              <div class="pt-1">
                                <h5 class="fs-14 mb-1" data-dz-name="">
                                  {{ file.name }}
                                </h5>
                                <p class="fs-13 text-muted mb-0" data-dz-size="">
                                  <strong>{{ file.size / 1024 }}</strong> KB
                                </p>
                                <strong class="error text-danger" data-dz-errormessage=""></strong>
                              </div>
                            </div>
                            <div class="flex-shrink-0 ms-3">
                              <button data-dz-remove="" class="btn btn-sm btn-danger" type="button"
                                @click="deleteRecord(index)">
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </ul>
                      <!-- <input type="file" id="file" class="form-control" ref="file" @change="onselect"> -->
                    </div>

                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="hstack gap-2 justify-content-end">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <div class="spinner-border" v-if="submitted" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <button type="submit" :disabled="submitted" class="btn btn-primary">{{ buttonname }}</button>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
        </div>
      </div>
    </div>

    <a data-bs-toggle="modal" id="opensessionbutton" data-bs-target="#opensession"></a>
    <div class="modal fade" id="opensession" tabindex="-1" aria-labelledby="exampleModalgridLabel">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalheader">View Session History</h5>
            <button type="button" @click="GetAllDocuments()" id="closeopensessionbutton" class="btn-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}
            </b-alert>


            <div class="chat-wrapper d-lg-flex  h-100  gap-1 mx-n4 mt-n4 p-1">
              <div class="chat-leftsidebar  h-100 ">
                <div class="px-4 pt-4 mb-4">
                  <div class="d-flex align-items-start">
                    <div class="flex-grow-1">
                      <h5 class="mb-4">Sessions Threads</h5>
                    </div>
                    <!-- <div class="flex-shrink-0">
                      <div data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom"
                        title="Add Contact">
                         Button trigger modal -->
                    <!-- <button type="button" class="btn btn-soft-success btn-sm">
                          <i class="ri-add-line align-bottom"></i>
                        </button>
                      </div> -->
                    <!-- </div> -->
                    <!-- </div>
                  <div class="search-box">
                    <input type="text" class="form-control bg-light border-light" placeholder="Search here..." />
                    <i class="ri-search-2-line search-icon"></i>
                  -->
                  </div>
                </div>


                <div class="chat-room-list" data-simplebar>
                  <div class="d-flex align-items-center px-4 mb-2">
                    <!-- <div class="flex-grow-1">
                      <h4 class="mb-0 fs-11 text-muted text-uppercase">
                        Session Threads
                      </h4>
                    </div> -->
                    <!-- <div class="flex-shrink-0">
                      <div data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom"
                        title="New Message">
                        Button trigger modal -->
                    <!-- <button type="button" class="btn btn-soft-success btn-sm">
                          <i class="ri-add-line align-bottom"></i>
                        </button>
                      </div>
                    </div> -->
                  </div>

                  <div class="chat-message-list">
                    <SimpleBar class="list-unstyled chat-list chat-user-list">
                      <li class @click="chatUsername(-1)" :class="{ active: username == 'All' }">
                        <a href="javascript: void(0);">
                          <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">

                              <!-- <div class="avatar-xxs" v-if="data.image">
                                <img :src="`${data.image}`" class="rounded-circle img-fluid userprofile" alt />
                              </div>-->
                              <div class="avatar-xxs">
                                <div class="avatar-title rounded-circle bg-success userprofile">
                                  .
                                </div>
                              </div>
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-truncate mb-1">
                                All
                              </p>
                            </div>

                            <div class="flex-shrink-0">

                            </div>
                          </div>
                        </a>
                      </li>
                      <li class v-for="(data, index) of chatData" :key="data.id" @click="chatUsername(index)"
                        :class="{ active: username == index }">
                        <a href="javascript: void(0);">
                          <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">

                              <!-- <div class="avatar-xxs" v-if="data.image">
                                <img :src="`${data.image}`" class="rounded-circle img-fluid userprofile" alt />
                              </div>-->
                              <div class="avatar-xxs" v-if="!data.image">
                                <div class="avatar-title rounded-circle bg-success userprofile">
                                  {{ index+ 1}}
                                </div>
                              </div>
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-truncate mb-1">
                                {{ data.progress_status }} ({{ data.creadybyname }})
                              </p>
                            </div>

                            <div class="flex-shrink-0">
                              <span class="badge badge-soft-dark rounded">{{
                                data.customecreatedat.split(' ')[0]
                              }}</span>
                            </div>
                          </div>
                        </a>
                      </li>
                    </SimpleBar>
                  </div>
                  <!-- End chat-message-list -->
                </div>
              </div>
              <!-- end chat leftsidebar -->
              <!-- Start User chat -->
              <div class="user-chat w-100 h-100 overflow-hidden">
                <div class="chat-content d-lg-flex  h-100 ">
                  <!-- start chat conversation section -->
                  <div class="w-100  h-100  overflow-hidden position-relative">
                    <!-- conversation user -->
                    <div class="position-relative h-100 ">
                      <div class="p-3 user-chat-topbar">
                        <div class="row align-items-center">
                          <div class="col-sm-4 col-8">
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 d-block d-lg-none me-3">
                                <a href="javascript: void(0);" class="user-chat-remove fs-18 p-1"><i
                                    class="ri-arrow-left-s-line align-bottom"></i></a>
                              </div>
                              <div class="flex-grow-1 overflow-hidden">
                                <div class="d-flex align-items-center">
                                  <div
                                    class="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                    <img :src="profile ? profile : require('@/assets/images/users/multi-user.jpg')"
                                      class="rounded-circle avatar-xs" alt="" />
                                    <span class="user-status"></span>
                                  </div>
                                  <div class="flex-grow-1 overflow-hidden">
                                    <h5 class="text-truncate mb-0 fs-16">
                                      <a class="text-reset username" data-bs-toggle="offcanvas"
                                        href="#userProfileCanvasExample" aria-controls="userProfileCanvasExample">{{
                                          currentselectedchatname
                                        }}</a>
                                    </h5>
                                    <p class="text-truncate text-muted fs-14 mb-0 userStatus">
                                      <small>{{ currentselecteddatetime }}</small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-sm-8 col-4">
                            <ul class="list-inline user-chat-nav text-end mb-0">
                              <li class="list-inline-item m-0">
                                <div class="dropdown">
                                  <button class="btn btn-ghost-secondary btn-icon" type="button"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <search-icon class="icon-sm"></search-icon>
                                  </button>
                                  <div class="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
                                    <div class="p-2">
                                      <div class="search-box">
                                        <input type="text" class="form-control bg-light border-light"
                                          placeholder="Search here..." onkeyup="searchMessages()" id="searchMessage" />
                                        <i class="ri-search-2-line search-icon"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <li class="list-inline-item d-none d-lg-inline-block m-0">
                                <button type="button" class="btn btn-ghost-secondary btn-icon"
                                  data-bs-toggle="offcanvas" data-bs-target="#userProfileCanvasExample"
                                  aria-controls="userProfileCanvasExample">
                                  <info-icon class="icon-sm"></info-icon>
                                </button>
                              </li>

                              <li class="list-inline-item m-0">
                                <div class="dropdown">
                                  <button class="btn btn-ghost-secondary btn-icon" type="button"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <more-vertical-icon class="icon-sm"></more-vertical-icon>
                                  </button>
                                  <div class="dropdown-menu dropdown-menu-end">
                                    <a class="dropdown-item d-block d-lg-none user-profile-show" href="#"><i
                                        class="ri-user-2-fill align-bottom text-muted me-2"></i>
                                      View Profile</a>
                                    <a class="dropdown-item" href="#"><i
                                        class="ri-inbox-archive-line align-bottom text-muted me-2"></i>
                                      Archive</a>
                                    <a class="dropdown-item" href="#"><i
                                        class="ri-mic-off-line align-bottom text-muted me-2"></i>
                                      Muted</a>
                                    <a class="dropdown-item" href="#"><i
                                        class="ri-delete-bin-5-line align-bottom text-muted me-2"></i>
                                      Delete</a>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div> -->
                        </div>
                      </div>
                      <!-- end chat user head -->

                      <div class="position-relative h-100 " id="users-chat">
                        <div class="chat-conversation  h-100  p-3 p-lg-4" id="chat-conversation" data-simplebar
                          ref="current">
                          <ul class="list-unstyled chat-conversation-list">
                            <li v-for="(data, index) of chatMessagesData" :key="data.id" :class="{
                              right: `${data.usertype}` === 'Bidder' || `${data.usertype}` === 'Super Admin',
                              left: `${data.usertype}` !== 'Bidder' && `${data.usertype}` !== 'Super Admin',
                            }" class="chat-list mb-4">
                              <div class="conversation-list">
                                <div class="chat-avatar">
                                  <div class="avatar-title rounded-circle bg-success userprofile">
                                    {{ index+ 1}}
                                  </div>
                                </div>
                                <!-- <div class="chat-avatar" v-if="data.align !== 'right'">
                                  <img :src="profile ? profile : require('@/assets/images/users/user-dummy-img.jpg')"
                                    alt="" />
                                </div> -->
                                <div class="user-chat-content">
                                  <div class="ctext-wrap">
                                    <div class="ctext-wrap-content">

                                      <p class="ctext-content" style="text-align: left;"><span
                                          style="font-weight:600">Status:</span> {{ data.progress_status }}</p>
                                      <p class="ctext-content" style="text-align: left;"><span
                                          style="font-weight:600">Crated at:</span> {{ data.customecreatedat }}</p>
                                      <p class="ctext-content" style="text-align: left;"><span
                                          style="font-weight:600">Crated by:</span> {{ data.creadybyname }} (<span
                                          v-if="data.usertype === 'Bidder'"> Operator </span><span
                                          v-if="data.usertype !== 'Bidder'"> {{ data.usertype }} </span>)</p>
                                      <p v-if="data.progress_status == 'Assigned' || data.progress_status == 'Edits' || data.progress_status == 'Rework'"
                                        class="ctext-content" style="text-align: left;"><span
                                          style="font-weight:600">Expiry date for Writer:</span> {{
                                            data.custome_writer_expiry
                                          }} </p>
                                      <p v-if="data.progress_status == 'Writer Completed'" class="ctext-content"
                                        style="text-align: left;"><span style="font-weight:600">Words:</span> {{
                                          data.words
                                        }} </p>
                                      <p v-if="data.progress_status == 'Writer Completed'" class="ctext-content"
                                        style="text-align: left;"><span style="font-weight:600">Plagiarism:</span> {{
                                          data.plagiarims
                                        }} % </p>
                                      <p v-if="data.progress_status == 'Writer Completed'" class="ctext-content"
                                        style="text-align: left;"><span style="font-weight:600">Grammerly:</span> {{
                                          data.grammerly
                                        }} </p>
                                      <p class="ctext-content" v-if="data.assigned_to !== -1" style="text-align: left;">
                                        <span>Assigned to :</span> {{ data.assigned_name }}
                                      </p>
                                      <!-- <p class="ctext-content" style="font-size:14px;text-align: left;"><u>Notes:</u>
                                      </p> -->
                                      <!-- <p class="ctext-content" style="text-align: left;">{{ data.notes }}</p> -->
                                      <p class="ctext-content" style="font-size:14px;text-align: left;">
                                        <u>Description:</u>
                                      </p>
                                      <p class="ctext-content" style="text-align: left;" v-html="data.description"></p>
                                      <p class="ctext-content" style="font-size:14px;text-align: left;"
                                        v-if="data.attachment_name !== ''"><u>Attachment:</u></p>
                                      <div class="ctext-content" v-if="data.attachment_name !== ''"
                                        style="text-align: left;">
                                        <p v-for="(filetodownload, indexi) of data.attachment_name.split('<separator>') "
                                          :key="indexi">
                                          <a :href="'' + rootulr + 'project/download/' + filetodownload" target="_blank"
                                            style="color: yellow;cursor: pointer;">
                                            {{ filetodownload }}
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                    <div class="dropdown align-self-start message-box-drop">
                                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-more-2-fill"></i>
                                      </a>
                                      <div class="dropdown-menu">
                                        <a @click="delete_single_session(data.id)"
                                          v-if="(usertype !== 'Writer') && (data.progress_status == 'Assigned' || data.progress_status == 'Rework' || data.progress_status == 'Edits')"
                                          class="dropdown-item delete-item" href="#"><i
                                            class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>Delete</a>
                                      </div>
                                    </div>
                                    <div class="conversation-name">
                                      <small class="text-muted time">{{
                                        data.time
                                      }}</small>
                                      <span class="text-success check-message-icon"><i
                                          class="ri-check-double-line align-bottom"></i></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>

                          <!-- end chat-conversation-list -->
                        </div>

                      </div>

                      <!-- end chat-conversation -->




                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
